import { createStandaloneToast } from "@chakra-ui/react";
import { ErrorsList } from "@components/Lists/ErrorsList";
import { QueryClientConfig } from "@tanstack/react-query";
import parseRequestError from "@utils/parseRequestError";

export const queryClientConfig: (
    toast: ReturnType<typeof createStandaloneToast>["toast"]
) => QueryClientConfig = toast => {
    function handleError(err: unknown) {
        const { title, errorsArr } = parseRequestError(err);

        toast({
            title,
            description: <ErrorsList errors={errorsArr} />,
            position: "top-right",
            status: "error",
        });
    }

    return {
        defaultOptions: {
            queries: {
                retry: 2,
                refetchOnWindowFocus: false,
            },
            mutations: { onError: handleError },
        },
    };
};
