import { BaseError } from "@interfaces/common";
import { msg } from "@lingui/core/macro";
import { AxiosError } from "axios";

const DEFAULT_ERROR_MESSAGE = msg`Unexpected error`.message;

export default function parseRequestError(err: unknown) {
    if (err instanceof AxiosError) {
        const data = err.response.data as {
            errors: BaseError[];
        };
        const firstError = data?.errors[0];

        const title = firstError?.title || DEFAULT_ERROR_MESSAGE;
        const errorsArr = data.errors.map(error => error.detail);

        return {
            title: `${title} (${err.response.status})`,
            description: errorsArr.join(", "),
            errorsArr,
        };
    }

    if (err instanceof Error) {
        return {
            title: DEFAULT_ERROR_MESSAGE,
            description: err.message,
            errorsArr: [err.message],
        };
    }
}
