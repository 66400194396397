import { set } from "lodash";
import type { Cleaned } from "@interfaces/common";

export const cleanObject = (object: Object, exclude?: string[]) => {
    return Object.fromEntries(
        Object.entries(object).filter(
            ([k, v]) =>
                ![undefined, null, ""].includes(v) && !exclude?.includes(k)
        )
    );
};

export const cleanNestedObject = <T extends object>(obj: T): Cleaned<T> => {
    const cleanedObj: any = {};

    Object.entries(obj).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
            if (typeof value === "object" && !Array.isArray(value)) {
                const cleanedNestedObj = cleanNestedObject(value);

                if (Object.keys(cleanedNestedObj).length > 0) {
                    cleanedObj[key] = cleanedNestedObj;
                }
            } else {
                cleanedObj[key] = value;
            }
        }
    });

    return cleanedObj as Cleaned<T>;
};

export const isObjectEmpty = (object: Object) => {
    if (!object) return true;
    return Object.keys(object).length === 0 && object.constructor === Object;
};

export const removeProperties = <T extends object, K extends keyof T>(
    obj: T,
    keysToRemove: K[]
): Omit<T, K> => {
    const result = { ...obj };
    keysToRemove.forEach(key => delete result[key]);
    return result;
};

export const flattenObject = (
    obj: Record<string, unknown>,
    prefix = ""
): Record<string, unknown> => {
    return Object.keys(obj).reduce((acc: Record<string, any>, key: string) => {
        const pre = prefix.length ? `${prefix}.` : "";
        if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            !Array.isArray(obj[key])
        ) {
            Object.assign(
                acc,
                flattenObject(obj[key] as Record<string, unknown>, pre + key)
            );
        } else {
            acc[pre + key] = obj[key];
        }
        return acc;
    }, {});
};

export const nestedObject = (object: Record<string, unknown>) => {
    return Object.entries(object).reduce((acc, [key, value]) => {
        set(acc, key, value);
        return acc;
    }, {});
};

export const filtersWithoutFirstPrefix = (
    filters: Record<string, unknown>
): Record<string, unknown> => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
        const keyWithoutFirstPrefix = key.split(".").slice(1).join(".");
        return { ...acc, [keyWithoutFirstPrefix]: value };
    }, {});
};
