import { List, ListIcon, ListItem } from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";

type ErrorsListProps = {
    errors: string[];
};

export const ErrorsList = ({ errors }: ErrorsListProps) => {
    return (
        <List spacing="2" maxH="32" overflowY="auto" my="6">
            {errors.map((error, index) => (
                <ListItem key={`error-${index}`} fontSize="sm" color="red.500">
                    <ListIcon as={FiAlertTriangle} />
                    {error}
                </ListItem>
            ))}
        </List>
    );
};
